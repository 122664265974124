<template lang="html">
  <div class="help-section">
    <div class="question">
      <span>
        <i class="fa fa-question-circle" />
        <IxRes>dlgCert.dashboard.hints.help</IxRes>
      </span>
    </div>
    <a href="#" @click.prevent="show = !show">
      <IxRes>dlgCert.dashboard.buttons.showHelp</IxRes>
    </a>
    <BsModal v-model="show">
      <template #title>
        <span>
          <i class="fa fa-question-circle" />
          <IxRes>dlgCert.dashboard.modals.title</IxRes>
        </span>
      </template>
      <slot />
      <template #footer>
        <div class="footer">
          <div>
            <span><IxRes>dlgCert.dashboard.modals.footer.question</IxRes></span>
            <!-- <span>Sind noch weitere fragen offen?</span> -->
          </div>
          <div>
            <!-- <span><IxRes>dlgCert.dashboard.modals.footer.contact</IxRes></span> -->
            <span>Melden Sie sich bitte bei XY per <a href="mailto:deine@email.de">E-Mail</a> oder rufen Sie uns an unter: <a href="tel:+499123456789">09123 456789</a></span>
          </div>
        </div>
      </template>
    </BsModal>
  </div>
</template>

<script>
import IxRes from '@components/IxRes'
import BsModal from '@components/bootstrap/BsModal'
export default {
  components: {
    BsModal,
    IxRes
  },
  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
.help-section {
  display: flex;

  .question, span i {
    margin-right: 5px;
  }
  margin-bottom: 5px;
}
::v-deep .modal-footer {
  text-align: left;
}
</style>
