<template lang="html">
  <div class="search-results" style="position: relative;">
    <template v-if="searchResult">
      <h4>
        <IxRes>Common.SR_Common.SearchResultFor</IxRes> '{{ searchText }}'
      </h4>
      <HelpBox type="warning">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SearchResultHint</IxRes>
      </HelpBox>
      <transition-group
        name="slide" tag="div"
        class="entity-list"
        :class="direction" mode="out-in"
      >
        <template v-for="(item, i) in firstResults">
          <div v-if="i === 0 || parentLookup[item.id] !== parentLookup[firstResults[i - 1].id]" :key="item.id + '_parent'" class="muted">
            <span v-if="parentLookup[item.id]">{{ parentLookup[item.id].name }}</span>
            <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoParentOrgUnit</IxRes>
          </div>
          <OrgUnitItem
            :key="item.id"
            :org-unit="item"
            :selected="selectedItem === item" @click.native="$emit('click', item.id)"
          />
        </template>
        <div v-if="moreResults" key="more-results" class="alert alert-info">
          <IxRes :context="{0: moreResults}">Common.SR_Common.MoreResults</IxRes>
        </div>
      </transition-group>
    </template>
    <div v-else class="alert alert-danger">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true" /> <IxRes>Common.SR_Common.NothingFound</IxRes>
    </div>

    <FrsLoadingIndicator small :loading="searching" />
  </div>
</template>

<script>
// TODO refactor using a new RocketSearchResults base, same for frs SearchResults

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import HelpBox from '@components/help/HelpBox'
import OrgUnitItem from '@components/nav/OrgUnitItem'

export default {
  components: {
    OrgUnitItem,
    HelpBox,
    FrsLoadingIndicator
  },
  props: {
    searchText: String,
    searchResult: Array,
    searching: Boolean,
    selectedItem: Object,
    parentLookup: Object
  },
  data: function () {
    return {
      direction: 'down'
    }
  },
  computed: {
    firstResults () {
      return this.searchResult ? this.searchResult.slice(0, 30) : []
    },
    moreResults () {
      return this.searchResult ? this.searchResult.length - this.firstResults.length : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; // firefox scrollbar issue
}

.alert {
  display: flex;
  align-items: center;

  .fa {
    margin-right: 10px;
    font-size: 1.5em;
  }
}

.entity-list {
  overflow-y: auto;
  overflow-x: hidden;

  .list-card {
    width: 95%;
    margin-left: 5%;
  }

  .muted {
    color: gray;
  }
}
</style>
