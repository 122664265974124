<template lang="html">
  <div class="company-selection">
    <h3>
      <IxRes>dlgCert.companySelection.header</IxRes>
    </h3>
    <HelpPrompt class="help">
      <DashboardHelp />
    </HelpPrompt>
    <p>
      <IxRes>dlgCert.companySelection.hints.introText</IxRes>
    </p>

    <div class="content">
      <div class="rocket-container">
        <Search
          v-model="searchText" :data="orgUnits"
          :keys="searchKeys"
          :threshold="0.3" @result="onSearchResult"
        />

        <SearchResults
          v-if="searchText"
          :searching="searching"
          :search-text="searchText"
          :search-result="searchResult"
          :selected-item="selectedOrgUnit"
          :parent-lookup="parentLookup"
          @click="$router.push({name: 'companySelection', params: {orgUnitId: $event}})"
        />
        <RocketNav
          v-else
          v-model="orgUnitId"
          :current-org="selectedOrgUnit"
          :parent-org="parentOrgUnit"
          :items="items"
        >
          <template #org="{item, selected}">
            <OrgUnitItem
              :org-unit="item"
              :selected="selected"
            >
              <template #title>
                <!-- <i v-if="item.isCompany" class="fa fa-building" /> -->

                <span class="org-name" :class="{'is-company': item.isCompany}">{{ item.name }}</span>
              </template>
            </OrgUnitItem>
          </template>
        </RocketNav>
      </div>

      <div class="button-container">
        <HelpBox v-if="!selectedOrgUnit">
          <IxRes>dlgCert.companySelection.hints.nothingSelected</IxRes>
        </HelpBox>
        <HelpBox v-else-if="!selectedOrgUnit.isCompany" type="warning">
          <IxRes>dlgCert.companySelection.hints.isNoCompany</IxRes>
        </HelpBox>
        <IxButton
          v-else
          large
          @click="goToDashboard"
        >
          <IxRes>dlgCert.companySelection.buttons.toDashboard</IxRes>
        </IxButton>
      </div>

      <div class="spacer" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapState, mapGetters, mapActions} from 'vuex'

// import {mapFormFields} from '@helpers/vuex'

import Search from '@components/search/Search'
import OrgUnitItem from '@components/nav/OrgUnitItem'
import RocketNav from '@components/nav/RocketNav'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'

import HelpPrompt from './widgets/HelpPrompt'
import DashboardHelp from './widgets/DashboardHelp'
import SearchResults from './widgets/SearchResults'

export default {
  components: {
    Search,
    SearchResults,
    RocketNav,
    OrgUnitItem,
    IxButton,
    HelpBox,
    HelpPrompt,
    DashboardHelp
  },
  data () {
    return {
      searching: false,
      searchKeys: ['name'],
      searchText: '',
      searchResult: null
    }
  },
  computed: {
    selectedOrgUnit () {
      return this.orgUnits.find(x => x.id === this.orgUnitId) || null
    },
    parentOrgUnit () {
      return this.parentLookup[this.orgUnitId] || null
    },
    ...mapState('dlgCert', [
      'companyIds',
      'preferences'
    ]),
    ...mapGetters('dlgCert', [
      'orgUnits',
      'orgForest',
      'parentLookup'
    ]),
    orgUnitId: {
      get () {
        return this.$route.params.orgUnitId
      },
      set (id) {
        this.onNavigate(id)
      }
    },
    items () {
      return this.selectedOrgUnit ? this.selectedOrgUnit.children : this.orgForest
    },
    api () {
      return axios.create({
        baseURL: '/api/v2/dlg-cert/preferences'
      })
    }
  },
  methods: {
    ...mapActions('dlgCert', [
      'persistSelectedOrgUnitId'
    ]),
    async onNavigate (id) {
      if (id !== this.orgUnitId) {
        this.$router.push({name: 'companySelection', params: {orgUnitId: id}})
      }
    },
    goToDashboard () {
      this.persistSelectedOrgUnitId(this.orgUnitId)
      this.$router.push({name: 'dashboard'})
    },
    async onSearchResult (value) {
      this.searching = true

      // nextTick() doesn´t work here
      await new Promise(resolve => setTimeout(resolve, 0))

      this.searchResult = value

      this.searching = false
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.searchText = ''
    next()
  },
  async created () {
    const {selectedOrgUnitId: orgUnitId} = this.preferences

    // TODO we should investigate if we can have this check in the router itself using route constraints

    // the selected orgUnitId is a user preference in the database
    // it might be that this orgUnit does not exist anymore
    const selectedOrgExists = this.orgUnits.find(x => x.id === orgUnitId)

    if (!this.orgUnitId && orgUnitId && selectedOrgExists) {
      this.$router.push({name: 'dashboard', params: {orgUnitId}})
    }
  }
}
</script>

<style lang="scss" scoped>
.company-selection {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  min-height: 0;

  display: flex;
  justify-content: space-between;
}

.rocket-container {
  width: 400px;

  display: flex;
  flex-direction: column;

  min-height: 0; // necessary for proper overflow handling of children, specifically for Firefox
  overflow-y: auto;
}

.button-container {
  flex: 1;

  flex-direction: column;
  display: flex;
  padding-left: 2em;
  align-items: flex-start;

  // margin-left: auto;
  // margin-top: 40px;
  // width: 30%;
}

.spacer {
  flex: 1;
}

.org-name:not(.is-company) {
  color: gray;
}
</style>
